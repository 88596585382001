/**
 * Written by Ronghua
 */

import { Box } from "@mui/material";
import React from "react";

import '../../assets/styles/backpack-elements-styles.css'

const greenTitleRectangleStyle = {
  display: "flex",
  margin: "auto",
  backgroundColor: "#C2D2A3",
  border: ".5vw solid black",
  borderRadius: "1vw",
  justifyContent: "center",

  color: "black",
  fontFamily: "Fredoka, sans-serif",
  fontWeight: 600,
  textAlign: "center",
  fontSize: "3vw",
};

const GreenTitleBar = ({ icon, title, boxWidth, topMargin }) => {
  if (topMargin === undefined) {
    topMargin = "12vh";
  }
  return (
    <div className="green-title-rectangle"
    >
      <img
        src={icon}
        className="green-bar-icon"
        alt="Icon"
      ></img>
      <div className="green-bar-title">
        {title}</div>
    </div>
  );
};

export default GreenTitleBar;
