/**
 * Written by Linh
 */

import React, { useEffect, useState } from "react";
import useSound from "use-sound";
import "../../assets/styles/selection-styles.css";
import { CorrectPopUp, IncorrectPopUp } from "../display/PopUp";

export default function MCVerticalRight({
  isSpanish,
  options,
  message,
  audioSource,
  setSelectorReturnValue,
}) {
  const [selectedOption, setSelectedOption] = useState(-1);
  const [showCorrectPopup, setShowCorrectPopup] = useState(false);
  const [showIncorrectPopup, setShowIncorrectPopup] = useState(false);
  const [audio] = useSound(process.env.REACT_APP_BACKEND_HOST + audioSource, {
    volume: 0.25,
    loop: false,
  });

  function handleClick(option, index) {
    if (option === "correctAnswer") {
      setShowCorrectPopup(true);
      setSelectedOption(index);
      audio(); // play jingle sound
    } else {
      setShowIncorrectPopup(true);
    }
  }

  useEffect(() => {
    setSelectedOption(null);
    setShowCorrectPopup(false);
    setShowIncorrectPopup(false);
  }, [options]);

  return (
    <div>
      <div className="mc-container">
        {/* setSelectorReturnValue determines if the answer is correct or incorrect */}
        {options !== undefined ? (
          options.map((option, index) => (
            <button
              className={`mc-btn ${isSpanish ? "es-mc-btn": ""} ${
                selectedOption === index ? "correct-btn" : ""
              } ${
                selectedOption !== null && selectedOption !== index
                  ? "inactive-btn"
                  : ""
              }`}
              disabled={selectedOption !== null && selectedOption !== index}
              onClick={() => {
                handleClick(option.name, index);
              }}
            >
              {isSpanish ? option.textEs : option.text}
            </button>
          ))
        ) : (
          <></>
        )}
      </div>
      {/* pop up for correct/incorrect answers */}
      {showCorrectPopup && (
        <CorrectPopUp
          isSpanish={isSpanish}
          message={message}
          setShowPopup={setShowCorrectPopup}
          setSelectorReturnValue={setSelectorReturnValue}
        />
      )}
      {showIncorrectPopup && (
        <IncorrectPopUp
          isSpanish={isSpanish}
          setShowPopup={setShowIncorrectPopup}
        />
      )}
    </div>
  );
}
