/**
 * Written by Alex and Ronghua
 */

import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import useSound from "use-sound";

import LeftButton from "../../assets/buttons/Left_Button.png";
import LeftButtonHovered from "../../assets/buttons/Left_Button_Hovered.png";
import RightButton from "../../assets/buttons/Right_Button.png";
import RightButtonHovered from "../../assets/buttons/Right_Button_Hovered.png";

import { globalContext } from "../../Context.js";
import useScreenSize from "../../ultilities/useScreenSize.js";
import buttonClick from "../../assets/audio/UI_Button-Click-1.mp3";
import ConditionalHighlight from "./ConditionalHighlight.js";

import "../../assets/styles/main-footer-styles.css";

const MainFooter = ({
  showLeftButton,
  showRightButton,
  highlightLeftButton,
  highlightRightButton,
  nameText,
  mainText,
  hintText,
  onlyShowArrows,
  returnUnit,
  useSmallerText,
}) => {
  const { slideId, setSlideId, state, setState, lessonId, setLessonId } =
    useContext(globalContext);
  const hideNameTag = nameText === "" || nameText === undefined;

  const [isHintVisible, setHintVisible] = useState(false);
  const [isLeftButtonHovered, setLeftButtonHovered] = useState(false);
  const [isRightButtonHovered, setRightButtonHovered] = useState(false);
  const navigate = useNavigate();
  const screenSize = useScreenSize();

  const handleLeftButtonHover = () => {
    setLeftButtonHovered(true);
  };
  const handleLeftButtonLeave = () => {
    setLeftButtonHovered(false);
  };

  const handleRightButtonHover = () => {
    setRightButtonHovered(true);
  };
  const handleRightButtonLeave = () => {
    setRightButtonHovered(false);
  };

  const [playButtonClick] = useSound(buttonClick, { volume: 0.25 });
  const handleLeftButtonClick = () => {
    playButtonClick();
    setSlideId(state.prevSlide);
  };

  const handleRightButtonClick = () => {
    playButtonClick();
    setSlideId(state.nextSlide);
  };

  const handleExitLessonClick = () => {
    navigate("../unitPage", { state: { unitId: returnUnit } });
  };

  const handleHintHover = () => {
    setHintVisible(true);
  };

  const handleHintLeave = () => {
    setHintVisible(false);
  };

  return (
    <div>
      {!onlyShowArrows && (
        <div className="footer">
          {/* character name */}
          {!hideNameTag && (
            <div className="blue-rectangle">
              <div className="blue-rectangle-text">{nameText}</div>
            </div>
          )}

          {/* hint text for ... */}
          {hintText && (
            <>
              <div
                className="hint-button"
                onMouseEnter={handleHintHover}
                onMouseLeave={handleHintLeave}
              >
                ?
              </div>
              <div
                className="hint-popup"
                style={{ visibility: isHintVisible ? "visible" : "hidden" }}
              >
                {hintText}
              </div>
            </>
          )}
          <div>
            {/* exit btn */}
            {returnUnit != null && (
              <div className="exit-rectangle" onClick={handleExitLessonClick}>
                <div className="exit-rectangle-text">Exit Lesson</div>
              </div>
            )}
            <div
              className={returnUnit != null ? "ending-text" : "main-text"}
              style={
                useSmallerText
                  ? { fontSize: screenSize.width * 0.026 + "px" }
                  : {}
              }
              dangerouslySetInnerHTML={{ __html: mainText }}
            />
          </div>
        </div>
      )}
      {/* left and right btns */}
      <div> 
        {/* conditional highlight used when hover on */}
        {/* left button to go back to previous slide */}
        {showLeftButton && (
          <ConditionalHighlight mark={state.highlightLeftArrow}>
            <img
              src={isLeftButtonHovered ? LeftButtonHovered : LeftButton}
              alt="Left Button"
              className={
                highlightLeftButton ? "highlighted button-left" : "button-left"
              }
              onClick={handleLeftButtonClick}
              onMouseEnter={handleLeftButtonHover}
              onMouseLeave={handleLeftButtonLeave}
            />
          </ConditionalHighlight>
        )}

        {/* right button to go to next slide, , for most of the 
        activities, right button would be hidden until user play the activity  */}
        {showRightButton && (
          <ConditionalHighlight mark={state.highlightRightArrow}>
            <img
              src={isRightButtonHovered ? RightButtonHovered : RightButton}
              alt="Right Button"
              className={
                highlightRightButton
                  ? "highlighted button-right"
                  : "button-right"
              }
              onClick={handleRightButtonClick}
              onMouseEnter={handleRightButtonHover}
              onMouseLeave={handleRightButtonLeave}
            />
          </ConditionalHighlight>
        )}
      </div>
    </div>
  );
};

export default MainFooter;
