/**
 * Written by Linh
 */

import React, { useEffect, useState } from "react";
import useSound from "use-sound";
import "../../assets/styles/selection-styles.css";
import { CorrectPopUp, IncorrectPopUp } from "../display/PopUp";

export default function MCPictureInBtn({
  isSpanish,
  question,
  options,
  message,
  audioSource,
  setSelectorReturnValue,
}) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [showCorrectPopup, setShowCorrectPopup] = useState(false);
  const [showIncorrectPopup, setShowIncorrectPopup] = useState(false);
  const [audio, { stop, duration }] = useSound(
    process.env.REACT_APP_BACKEND_HOST + audioSource,
    {
      volume: 0.25,
      loop: false,
    }
  );

  // handle pop-up and correct answer btn effect
  function handleClick(option, index) {
    if (option === "correctAnswer") {
      setShowCorrectPopup(true);
      setSelectedOption(index);
      audio(); // play jingle sound
    } else {
      setShowIncorrectPopup(true);
    }
  }

  useEffect(() => {
    setSelectedOption(null);
    setShowCorrectPopup(false);
    setShowIncorrectPopup(false);
  }, [question, options]);

  return (
    <div>
      <div className="horizontal-mc-container">
        <div className="question-rectangle">
          <div className="question-text">{question}</div>
        </div>
        <div className="picture-btn-container">
          {/* setSelectorReturnValue determines if the answer is correct or incorrect */}
          {options !== undefined ? (
            options.map((option, index) => (
              <button
                className={`picture-btn ${
                  selectedOption === index ? "correct-btn" : ""
                } ${
                  selectedOption !== null && selectedOption !== index
                    ? "inactive-btn"
                    : ""
                }`}
                disabled={selectedOption !== null && selectedOption !== index}
                onClick={() => {
                  handleClick(option.name, index);
                }}
              >
                <div className="picture-container">
                  <img
                    src={process.env.REACT_APP_BACKEND_HOST + option.media[0]}
                    alt=""
                  />
                </div>
                {isSpanish ? option.textEs : option.text}
              </button>
            ))
          ) : (
            <></>
          )}
        </div>
      </div>
      {/* pop up for correct/incorrect answer */}
      {showCorrectPopup && (
        <CorrectPopUp
          isSpanish={isSpanish}
          message={message}
          setShowPopup={setShowCorrectPopup}
          setSelectorReturnValue={setSelectorReturnValue}
        />
      )}
      {showIncorrectPopup && (
        <IncorrectPopUp
          isSpanish={isSpanish}
          setShowPopup={setShowIncorrectPopup}
        />
      )}
    </div>
  );
}
