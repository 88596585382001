/**
 * Written by Linh
 */

import { useState } from "react";
import { useReactMediaRecorder } from "react-media-recorder-2";
import useSound from "use-sound";

import startRecord from "../../assets/audio/Start-Recording.mp3";
import stopRecord from "../../assets/audio/Stop-Recording.mp3";
import "../../assets/styles/display-styles.css";

import { BACKEND_LINK } from "../../ultilities/globalConstants";

export default function Record({ isSpanish, setSelectorReturnValue }) {
  const {
    status,
    startRecording,
    stopRecording,
    pauseRecording,
    mediaBlobUrl,
  } = useReactMediaRecorder({
    video: false,
    audio: true,
    echoCancellation: true,
  });

  const [recordButtonState, setRecordButtonState] = useState(
    "Recording_Start.png"
  );
  const [recordAudioStart] = useSound(startRecord);
  const [recordAudioStop] = useSound(stopRecord);

  const handleRecordClick = () => {
    // TODO: turn this on when user submit the recording later
    setSelectorReturnValue("turnOnNextBtn");

    if (recordButtonState === "Recording_Start.png") {
      setRecordButtonState("Recording_Stop.png");
      recordAudioStart();
      startRecording();
    } else {
      setRecordButtonState("Recording_Start.png");
      recordAudioStop();
      stopRecording();
      // TODO: send media blob to backend
    }
  };

  return (
    <div>
      <div className="post-it-container">
        <img src={`${BACKEND_LINK}img/P_Postit.png`} alt="Post-it" />

        <p className="record-text-container record-text">
          {isSpanish
            ? "¡Presiona para grabar y vuelve a presionar para terminar!"
            : "Press to record and press again to finish!"}
        </p>

        {/* record btn */}
        <img
          src={`${BACKEND_LINK}ui/${recordButtonState}`}
          onClick={handleRecordClick}
          alt="Record button"
          className="record-btn"
        />
      </div>
    </div>
  );
}
