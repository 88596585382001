import React, { useState, useEffect } from "react";
import { CorrectPopUp, IncorrectPopUp } from "../display/PopUp";
import "../../assets/styles/mc-picture-styles.css";
import useSound from "use-sound";

export default function MCPicture({
  options,
  message,
  audioSource,
  setSelectorReturnValue,
}) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [showCorrectPopup, setShowCorrectPopup] = useState(false);
  const [showIncorrectPopup, setShowIncorrectPopup] = useState(false);
  const [audio, { stop, duration }] = useSound(
    process.env.REACT_APP_BACKEND_HOST + audioSource,
    { volume: 0.25, loop: false }
  );

  // handle pop-up and correct answer btn effect
  function handleClick(option, index) {
    if (option === "correctAnswer") {
      setShowCorrectPopup(true);
      setSelectedOption(index);
      audio(); // play jingle sound
    } else {
      setShowIncorrectPopup(true);
    }
  }

  useEffect(() => {
    setSelectedOption(null);
    setShowCorrectPopup(false);
    setShowIncorrectPopup(false);
  }, [options]);

	const optionsContainer = options.length === 2 ? "two-options-container" : "picture-btn-container-2";

  return (
    <div>
      <div className="horizontal-mc-picture-container">
				<div className={optionsContainer}>
          {options !== undefined ? (
            options.map((option, index) => (
              <div className="button-img-container" key={index}>
                <button
                  className={`picture-btn-2 ${
                    selectedOption === index ? "correct-btn" : ""
                  } ${
                    selectedOption !== null && selectedOption !== index
                      ? "inactive-btn"
                      : ""
                  }`}
                  disabled={selectedOption !== null && selectedOption !== index}
                  onClick={() => {
                    handleClick(option.name, index);
                  }}
                >
                  {option.text}
                </button>
                <div className="picture-container-2">
                  {console.log(process.env.REACT_APP_BACKEND_HOST + option.media[0])}
                  <img
                    src={process.env.REACT_APP_BACKEND_HOST + option.media[0]}
                    alt=""
                    onClick={() => {
                      handleClick(option.name, index);
                    }}
                  />
                </div>
              </div>
            ))
          ) : (
            <></>
          )}
        </div>
      </div>
      {showCorrectPopup && (
        <CorrectPopUp
          message={message}
          setShowPopup={setShowCorrectPopup}
          setSelectorReturnValue={setSelectorReturnValue}
        />
      )}
      {showIncorrectPopup && (
        <IncorrectPopUp setShowPopup={setShowIncorrectPopup} />
      )}
    </div>
  );
}
