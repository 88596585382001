/**
 * Written by Alex, Linh and Ronghua
 */

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import PreviousArrowButton from "../components/backpacks/BackArrowButton";
import ContentListing from "../components/backpacks/ContentListing";
import TitleText from "../components/backpacks/TitleText";

import "../assets/styles/lesson-selection-styles.css";
import "../assets/styles/tutorials-adjustments-styles.css";
import "../assets/styles/backgrounds-styles.css";

export default function UnitPage({ showTutorial }) {
  const location = useLocation();
  const [unitId, setUnitId] = useState(
    location.state === null
      ? "6663546d8060991a53417aa3"
      : location.state.unitId === undefined
      ? "6663546d8060991a53417aa3"
      : location.state.unitId
  );
  const [state, setState] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    fetch(process.env.REACT_APP_BACKEND_HOST + "api/getUnit/" + unitId)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setState(data);
      });
  }, [unitId]);

  return (
    <div className="background volcano-bg scrollable-page-body">
      <TitleText
        text={state.name}
        forBackpackLabels={showTutorial ? true : false}
      />

      {!showTutorial && (
        <PreviousArrowButton // TODO not linked correctly
          previousPageName={"modulePage"}
        />
      )}

      <div
        className={`scrollable-item-selection ${
          showTutorial ? "module-tutorial" : ""
        }`}
      >
        {/* TODO Linh idk what the sytax is: style={{...scrollableLessonsStyle, ...(showTutorial && forTutoriaStyle)}}> */}
        {state.lessons !== undefined ? (
          state.lessons.map((lesson) => (
            // show list of lesson and its icon + title
            <ContentListing
              completed={false} // TODO: completed whether the player has finished the lesson, its not implemented yet
              available={true}
              nameOfTopic={"Lesson " + lesson.num + ":\n" + lesson.name}
              lessonThumbnail={lesson.thumbnail}
              scrollingCheck={true}
              modulesCheck={false}
              handleButtonClick={() => {
                navigate("../lessonPage", { state: { lessonId: lesson._id } });
              }}
            />
          ))
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
