/**
 * Written by Linh
 */

import { useState } from "react";
import ContinueButton from "../../assets/buttons/Continue_Button.png";
import ContinueButtonHovered from "../../assets/buttons/Continue_Button_Hovered.png";
import "../../assets/styles/popup-styles.css";

// correct popup shown when user clicks on correct answer
export function CorrectPopUp({
  isSpanish,
  message,
  setShowPopup,
  setSelectorReturnValue,
}) {
  const [isContinueBtnHovered, setContinueBtnHovered] = useState(false);

  function handleClick() {
    setShowPopup(false);
    setSelectorReturnValue("correctAnswer");
  }

  const handleContinueBtnHover = () => {
    setContinueBtnHovered(true);
  };
  const handleContinueBtnLeave = () => {
    setContinueBtnHovered(false);
  };

  return (
    <div className="popup-bg">
      <div className="popup-rectangle">
        <h3 className="popup-title">
          {isSpanish ? "¡Es Correcto!" : "That is Correct!"}
        </h3>
        <p className="popup-text">{message}</p>
      </div>
      <img
        src={isContinueBtnHovered ? ContinueButtonHovered : ContinueButton}
        className="continue-btn"
        alt="Continue button"
        onClick={handleClick}
      />
    </div>
  );
}

// incorrect popup shown when user clicks on wrong answer
export function IncorrectPopUp({ isSpanish, setShowPopup }) {
  const [isContinueBtnHovered, setContinueBtnHovered] = useState(false);

  function handleClick() {
    setShowPopup(false);
  }

  const handleContinueBtnHover = () => {
    setContinueBtnHovered(true);
  };
  const handleContinueBtnLeave = () => {
    setContinueBtnHovered(false);
  };

  return (
    <div className="popup-bg-2">
      <div className="popup-rectangle">
        <p className="popup-title-2">
          {isSpanish ? "¡Intenta otra vez!" : "Try again!"}
        </p>
      </div>
      <img
        src={isContinueBtnHovered ? ContinueButtonHovered : ContinueButton}
        className="continue-btn"
        alt="Continue button"
        onClick={handleClick}
        onMouseEnter={handleContinueBtnHover}
        onMouseLeave={handleContinueBtnLeave}
      />
    </div>
  );
}

// general popup shown when user clicks on wrong answer
export function PopUp({ setShowPopup, message }) {
  const handleClick = (e) => {
    setShowPopup(false);
  };
  return (
    <div className="popup-bg-2" onClick={handleClick}>
      <div className="general-popup-rectangle">
        <p className="popup-title-3">{message}</p>
      </div>
    </div>
  );
}
