/**
 * Written by Ronghua and Linh
 */

import { useContext, useEffect, useState } from "react";
import { globalContext } from "../../../Context";
import ReactPlayer from "react-player/lazy";

import highlightedImage from "../../backpacks/HighlightImage.js";

import speakIcon from "../../../assets/icons/UI_Speak.png";
import muteIcon from "../../../assets/icons/UI_Mute.png";
import "../../../assets/styles/lesson-selection-styles.css";
import "../../../assets/styles/buttons-styles.css";

export default function SpeakingButton({ highlighted }) {
  const { state, setState, isSpanish, setIsSpanish } =
    useContext(globalContext);

  const [num, setNum] = useState(isSpanish ? 1 : 0);
  const [playingVO, setPlayingVO] = useState(false);

  useEffect(() => {
    if (isSpanish) {
      setNum(1);
    } else {
      setNum(0);
    }
  }, [isSpanish]);

  const handlePlay = () => {
    if (playingVO) {
      setPlayingVO(false);
    } else {
      setPlayingVO(true);
    }
  };
  return (
    <div className="speaking-button-container">
      <ReactPlayer
        url={
          process.env.REACT_APP_BACKEND_HOST +
          (state.voiceovers !== undefined ? state.voiceovers[num] : "")
        }
        playing={playingVO}
      />
      <img
        src={playingVO ? speakIcon : muteIcon}
        style={{ height: "100%", ...(highlighted && highlightedImage) }}
        onClick={() => {
          handlePlay();
        }}
        alt="Speaking icon"
      ></img>
    </div>
  );
}
