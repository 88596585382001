/**
 * Written by Ayaan
 */
import React, { useState, useRef, useEffect } from 'react';
import { DndContext, useDraggable, useDroppable, DragOverlay } from '@dnd-kit/core';
import useSound from "use-sound";
import '../../assets/styles/drag-drop-styles.css';
import { CorrectPopUp, IncorrectPopUp } from "../display/PopUp";

// DraggableStory component
function DraggableStory({ id, children }) {
  const {attributes, listeners, setNodeRef, transform} = useDraggable({
    id: id,
  });
  const style = transform ? {
    transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
    zIndex: 1000, // Add this line to ensure it's on top when dragging
  } : undefined;

  return (
    <div ref={setNodeRef} style={style} {...listeners} {...attributes} className="draggable-wrapper">
      {children}
    </div>
  );
}

// DroppableStory component
function DroppableStory({ id, children, isOccupied }) {
  const { setNodeRef } = useDroppable({
    id: id,
    disabled: isOccupied,
  });
  
  return (
    <div ref={setNodeRef} className="droppable-container">
      <div className="droppable-zone">
        {children}
      </div>
    </div>
  );
}

// Main DragAndDrop component
function DragAndDrop({ options, message, audioSource, setSelectorReturnValue }) {
  const [items, setItems] = useState(options.map((item, index) => ({ 
    id: `item-${index}`,
    location: 'outside', 
    media: process.env.REACT_APP_BACKEND_HOST + item.media, 
    name: item.name 
  })));
  const [activeId, setActiveId] = useState(null);
  const [showCorrectPopup, setShowCorrectPopup] = useState(false);
  const [showIncorrectPopup, setShowIncorrectPopup] = useState(false);
  const [audio, { stop, duration }] = useSound(process.env.REACT_APP_BACKEND_HOST + audioSource, {
    volume: 0.25,
    loop: false,
  });

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    
    if (over && ['A', 'B', 'C', 'D'].includes(over.id)) {
      setItems(prevItems => {
        const newItems = prevItems.map(item => {
          if (item.id === active.id) {
            return { ...item, location: over.id };
          }
          if (item.location === over.id) {
            return { ...item, location: 'outside' };
          }
          return item;
        });

        // Check if all droppable areas are filled
        const filledAreas = newItems.filter(item => ['A', 'B', 'C', 'D'].includes(item.location));
        if (filledAreas.length === 4) {
          const isAllCorrect = filledAreas.every(item => item.name === "correctMultipleAnswer");
          if (isAllCorrect) {
            setShowCorrectPopup(true);
            audio(); // play jingle sound
          } else {
            setShowIncorrectPopup(true);
            // Reset incorrect items
            return newItems.map(item => 
              item.name !== "correctMultipleAnswer" ? { ...item, location: 'outside' } : item
            );
          }
        }

        return newItems;
      });
    } else {
      setItems(prevItems => prevItems.map(item => 
        item.id === active.id ? { ...item, location: 'outside' } : item
      ));
    }

    setActiveId(null);
  };

  const renderItem = (item) => (
    <DraggableStory key={item.id} id={item.id}>
      <div 
        className={item.location !== 'outside' ? 'dropped-item' : 'draggable-item'}
      >
        <img 
          src={item.media} 
          alt={item.name}
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            width: 'auto',
            height: 'auto',
            objectFit: 'contain'
          }}
        />
      </div>
    </DraggableStory>
  );

  const renderPlaceholder = (key) => (
    <div key={key} className="placeholder-item draggable-item">
      {/* Transparent placeholder */}
    </div>
  );

  const renderImages = (startIndex, endIndex) => {
    const sectionItems = items.slice(startIndex, endIndex);
    const itemsToRender = [];

    for (let i = 0; i < endIndex - startIndex; i++) {
      const item = sectionItems[i];
      if (item && item.location === 'outside') {
        itemsToRender.push(renderItem(item));
      } else {
        itemsToRender.push(renderPlaceholder(`placeholder-${startIndex}-${i}`));
      }
    }

    return itemsToRender;
  };

  useEffect(() => {
    setItems(options.map((item, index) => ({ 
      id: `item-${index}`,
      location: 'outside', 
      media: process.env.REACT_APP_BACKEND_HOST + item.media, 
      name: item.name 
    })));
    setShowCorrectPopup(false);
    setShowIncorrectPopup(false);
  }, [options]);

  return (
    <div className="drag-drop-component">
      <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
        <div className="drag-drop-container">
          <div className="drag-drop-layout">
            <div className="top-images">
              {renderImages(0, 2)}
            </div>
            <div className="main-content">
              <div className="left-images">
                {renderImages(2, 5)}
              </div>
              <div className="grid">
                {['A', 'B', 'C', 'D'].map((id) => {
                  const occupyingItem = items.find(item => item.location === id);
                  return (
                    <DroppableStory key={id} id={id} isOccupied={!!occupyingItem}>
                      {occupyingItem ? 
                        renderItem(occupyingItem) : 
                        <div className="drop-placeholder"></div>
                      }
                    </DroppableStory>
                  );
                })}
              </div>
              <div className="right-images">
                {renderImages(5, 8)}
              </div>
            </div>
          </div>
        </div>
        <DragOverlay dropAnimation={null}>
          {activeId ? (
            <div className="drag-overlay">
              {renderItem(items.find(item => item.id === activeId))}
            </div>
          ) : null}
        </DragOverlay>
      </DndContext>
      {showCorrectPopup && (
        <CorrectPopUp
          message={message}
          setShowPopup={setShowCorrectPopup}
          setSelectorReturnValue={setSelectorReturnValue}
        />
      )}
      {showIncorrectPopup && (
        <IncorrectPopUp setShowPopup={setShowIncorrectPopup} />
      )}
    </div>
  );
}

export default DragAndDrop;