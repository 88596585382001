/**
 * Written by Linh
 */

import React, { useEffect, useState } from "react";
import useSound from "use-sound";
import "../../assets/styles/selection-styles.css";
import { CorrectPopUp, IncorrectPopUp } from "../display/PopUp";

export default function MCLeftRight({
  isSpanish,
  question,
  options,
  message,
  audioSource,
  setSelectorReturnValue,
}) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [showCorrectPopup, setShowCorrectPopup] = useState(false);
  const [showIncorrectPopup, setShowIncorrectPopup] = useState(false);
  const [correctAnswerAudio] = useSound(
    process.env.REACT_APP_BACKEND_HOST + audioSource[0],
    { volume: 0.25, loop: false }
  );
  const [incorrectAnswerAudio] = useSound(
    process.env.REACT_APP_BACKEND_HOST + audioSource[1],
    { volume: 0.25, loop: false }
  );

  // handle pop-up and correct answer btn effect
  function handleClick(option, index) {
    if (option === "correctAnswer") {
      setShowCorrectPopup(true);
      setSelectedOption(index);
      correctAnswerAudio(); // play correct sound
    } else {
      setShowIncorrectPopup(true);
      incorrectAnswerAudio(); // play incorrect sound 
    }
  }

  useEffect(() => {
    setSelectedOption(null);
    setShowCorrectPopup(false);
    setShowIncorrectPopup(false);
  }, [question, options]);

  return (
    <div>
      <div className="left-right-question-container">
        <div className={`${!question ? "" : "higher-question-rectangle"}`}>
          <div className="question-text">{question}</div>
        </div>
        {/* show option */}
        <div className="left-right-container">
          {options !== undefined ? (
            options.map((option, index) => (
              <button
                className={`left-right-btn ${
                  selectedOption === index ? "correct-btn" : ""
                } ${
                  selectedOption !== null && selectedOption !== index
                    ? "inactive-btn"
                    : ""
                }`}
                disabled={selectedOption !== null && selectedOption !== index}
                onClick={() => {
                  handleClick(option.name, index);
                }}
              >
                {isSpanish ? option.textEs : option.text}
              </button>
            ))
          ) : (
            <></>
          )}
        </div>
      </div>
      {/* pop up for correct and incorrect answer */}
      {showCorrectPopup && (
        <CorrectPopUp
          isSpanish={isSpanish}
          message={message}
          setShowPopup={setShowCorrectPopup}
          setSelectorReturnValue={setSelectorReturnValue}
        />
      )}
      {showIncorrectPopup && (
        <IncorrectPopUp
          isSpanish={isSpanish}
          setShowPopup={setShowIncorrectPopup}
        />
      )}
    </div>
  );
}
