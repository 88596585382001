/**
 * Written by Ronghua
 */

import React from "react";
import { Box } from "@mui/material";
import useScreenSize from "../../ultilities/useScreenSize";

const RedTextBox = ({ width, height, top, left, text, fontSize }) => {
  const screenSize = useScreenSize();
  return (
    <Box
      sx={{
        border: screenSize.width * 0.005 + "px solid black",
        width: (width / 1280) * screenSize.width + "px",
        height: (height / 832) * screenSize.height + "px",
        top: (top / 832) * screenSize.height + "px",
        left: (left / 1280) * screenSize.width + "px",
        position: "absolute",

        backgroundColor: "#a7e4e1",
        border: screenSize.width * 0.005 + "px solid black",
        borderRadius: screenSize.width * 0.02 + "px",
        display: "flex",

        color: "red",
        fontFamily: "Fredoka, sans-serif",
        fontWeight: 400,
        textAlign: "center",
        fontSize: (fontSize / 8.5 / 100) * screenSize.height + "px",
        fontWeight: "550",
      }}
    >
      <Box sx={{ margin: "auto" }}> {text} </Box>
    </Box>
  );
};

export default RedTextBox;
